import { IoIosArrowDown, IoIosArrowUp, IoMdCopy } from "react-icons/io";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiTrash } from "react-icons/fi";
import { GrTextAlignFull } from "react-icons/gr";
import SelectInput from "../../../../../../../Components/base/SelectInput";
import { SetSelectedPrompt } from "../../../../../store/slice/promptsSlice";
import SimpleInput from "../../../../../../../Components/base/SimpleInput";
import { useDispatch } from "react-redux";
import { useState } from "react";

const PromptInput = ({ prompt, field, setSelectedInput, selectedInput }) => {
  // Store
  const dispatch = useDispatch();

  const onInputsDataChange = (name) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    const updatedPrompt = {
      ...prompt,
      fields: prompt.fields.map((field) => {
        if (field._id === selectedInput) {
          return {
            ...field,
            [name]: value,
          };
        }
        return field;
      }),
    };

    dispatch(SetSelectedPrompt(updatedPrompt));
  };

  const onFocusedPromptChange = (id) => () => {
    setSelectedInput(id);
  };

  const onRemoveField = (id) => () => {
    const updatedPrompt = {
      ...prompt,
      fields: prompt.fields.filter((field) => field._id !== id),
    };
    dispatch(SetSelectedPrompt(updatedPrompt));
  };

  return (
    <div>
      <div className="flex w-full p-3 items-center justify-between mb-4">
        <div className="flex">
          <GrTextAlignFull size={20} />
          <h4 className="text-sm text-gray-700 ml-3">{`[${field.name}]`}</h4>
        </div>
        <div className="flex items-center">
          <CopyToClipboard text={`[${field.name}]`}>
            <i className="cursor-pointer text-blue-400" title="copy">
              <IoMdCopy size={20} />
            </i>
          </CopyToClipboard>

          <i className="ml-1 cursor-pointer text-blue-400" title="copy ID">
            {selectedInput !== field._id ? (
              <IoIosArrowDown
                size={20}
                onClick={onFocusedPromptChange(field._id)}
              />
            ) : (
              <IoIosArrowUp size={20} onClick={onFocusedPromptChange("")} />
            )}
          </i>
        </div>
      </div>
      {selectedInput === field._id && (
        <form className="p-3 bg-gray-100 mx-2">
          <SimpleInput
            type="text"
            value={field.name}
            onChange={onInputsDataChange("name")}
            label="Name *"
          />
          <SimpleInput
            type="text"
            value={field.label}
            onChange={onInputsDataChange("label")}
            label="Question"
          />
          <SimpleInput
            type="text"
            value={field.placeholder}
            onChange={onInputsDataChange("placeholder")}
            label="Placeholder"
          />
          <SimpleInput
            type="number"
            value={field.maxLength}
            onChange={onInputsDataChange("maxLength")}
            label="Max Length"
          />
          <SelectInput
            name="width"
            label="Width"
            defaultValue={field.width}
            onChange={onInputsDataChange("width")}
            options={["large", "medium", "small"]}
          />
          <SimpleInput
            type="number"
            r
            value={field.rows}
            onChange={onInputsDataChange("rows")}
            label="Rows"
          />
          <label className="inline-flex items-center my-2 mx-2">
            <input
              type="checkbox"
              name="required"
              className="form-checkbox h-5 w-5 cursor-pointer"
              checked={field.required}
              onChange={onInputsDataChange("required")}
            />
            <span className="ml-2 text-gray-700">Required</span>
          </label>
          {!field.required ? (
            <div>
              <label
                htmlFor="template"
                className="block text-sm font-semibold mb-2"
              >
                Template
              </label>
              <textarea
                name="template"
                rows="4"
                value={field.template}
                onChange={onInputsDataChange("template")}
                className="resize-none w-full border-2 border-gray-200 p-3"
              ></textarea>
            </div>
          ) : null}
          <div className="w-full text-right">
            <button
              type="button"
              className="bg-white p-2 rounded w-max mx-1"
              onClick={onRemoveField(field._id)}
            >
              <FiTrash size={22} color="red" />
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PromptInput;
