import {
  AddPrompt,
  RefreshList,
  SetSelectedPrompt,
  UpdatePrompts,
  GptModels,
} from "../../store/slice/promptsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Loading from "../../../../Components/base/Loading";
import Modal from "../../../../Components/base/Modal";
import PromptItem from "./PromptItem";
import SimpleInput from "../../../../Components/base/SimpleInput";
import axios from "../../utils/axios";
import { emptyPrompt } from "../../utils";
import { toast } from "react-toastify";
import { AiTwotoneCalendar } from "react-icons/ai";
import { FiEdit, FiTrash } from "react-icons/fi";
import IconGpt from "../../../../assets/gpt-icon-g.png";

const promptItems = [
  "Nom",
  "Ordre",
  "Gpt Model",
  "Group",
  "Dernière mise à jour",
  "",
];
const groupItems = ["Nom", "Order", "Dernière mise à jour", ""];

const Prompts = () => {
  const dispatch = useDispatch();
  const { prompts, refreshList, selectedPrompt } = useSelector(
    (state) => state.prompts
  );
  // states
  const [isNewPromptModalOpen, setNewPromptModalOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [gptRateLimit, setGptRateLimit] = useState(-1);
  const [promptsData, setPromptData] = useState({});
  const [page, setPage] = useState(1);
  const [newPromptFormData, setNewPromptFormData] = useState({
    ...emptyPrompt,
  });
  const [groups, setGroups] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupOrder, setGroupOrder] = useState(0);
  const [isNewGroupModalOpen, setNewGroupModalOpen] = useState(false);
  const [isUpdateGroupModalOpen, setUpdateGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  const [loading, setLoading] = useState(false);

  const onCloseNewPromptModal = () => {
    setNewPromptModalOpen(false);
  };

  const onOpenNewPromptModal = () => {
    setNewPromptModalOpen(true);
  };

  const onCloseNewGroupModal = () => {
    setNewGroupModalOpen(false);
  };

  const onOpenNewGroupModal = () => {
    setNewGroupModalOpen(true);
  };

  const onCloseUpdateGroupModal = () => {
    setUpdateGroupModalOpen(false);
  };

  const onOpenUpdateGroupModal = () => {
    setUpdateGroupModalOpen(true);
  };

  const createNewGroup = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post("api/v1/prompt-group", {
        name: groupName,
      });
      setGroups(res.data);
      toast.success("New group created successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setGroupName("");
      onCloseNewGroupModal();
      setLoading(false);
    }
  };

  const deleteGroup = async (id) => {
    setLoading(true);
    try {
      const res = await axios.delete(`api/v1/prompt-group/${id}`);
      setGroups(res.data.promptGroups);
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const updateGroup = async (id) => {
    setLoading(true);
    try {
      const res = await axios.patch(`api/v1/prompt-group/${id}`, {
        name: groupName,
        order: groupOrder,
      });
      setGroups(res.data.promptGroups);
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setGroupName("");
      onCloseUpdateGroupModal();
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const res = await axios.get("api/v1/prompt-group");
        setGroups(res.data);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGroups();
  }, []);

  const onLimitModalOpen = () => setIsLimitModalOpen(true);
  const onLimitModalClose = () => setIsLimitModalOpen(false);

  const onNewPromptDataChange = (field) => (event) => {
    setNewPromptFormData((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleCreatePromptSuccess = (data) => {
    dispatch(AddPrompt(data));
    dispatch(SetSelectedPrompt(data));
    setNewPromptModalOpen(false);

    toast.success("Prompt created successfully");
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (newPromptFormData.label === "") {
      toast.error("Please enter a valid prompt name");
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post("api/v1/prompts", newPromptFormData);
      if (res.status === 201) handleCreatePromptSuccess(res.data);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setNewPromptFormData((prev) => ({ ...prev, label: "" }));
      setLoading(false);
    }
  };

  const sortPrompts = (a, b) => {
    if (a.order < b.order) return 1;
    if (a.order > b.order) return -1;
    return 0;
  };

  const onSubmitGptRateLimit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const res = await axios.put("api/v1/gptRateLimit/", {
        limit: gptRateLimit,
      });

      if (res?.data?.message && res?.status === 200)
        toast.success(res.data.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
      onLimitModalClose();
    }
  };

  useEffect(() => {
    dispatch(RefreshList(false));
    const getPromptsData = async () => {
      setLoading(true);
      try {
        const { data, status } = await axios.get("api/v1/prompts/", {
          params: { page },
        });
        if (status === 200) {
          const sortedPrompts = data.docs.sort(sortPrompts);
          dispatch(UpdatePrompts(sortedPrompts));
          setPromptData(data);
        }

        const gptRateLimitRes = await axios.get("api/v1/gptRateLimit/");
        if (
          gptRateLimitRes &&
          gptRateLimitRes.data?.data?.limit &&
          gptRateLimitRes.status === 200
        )
          setGptRateLimit(gptRateLimitRes.data.data.limit);

        const gptModelsRes = await axios.get("api/v1/gpt-model/");
        if (gptModelsRes && gptModelsRes.data?.data)
          dispatch(GptModels(gptModelsRes.data?.data));
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };
    getPromptsData();
  }, [page, refreshList]);

  if (selectedPrompt || gptRateLimit < 0) return <></>;
  return (
    <div className="p-3 md:p-6 bg-gray-100 h-full overflow-hidden">
      <div className=" max-w-screen-2xl w-full m-auto flex flex-col">
        <header className="flex justify-between">
          <h2 className="font-bold text-xl">Prompts</h2>
          <div className="flex gap-2">
            <button
              className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
              onClick={onLimitModalOpen}
            >
              Configurer limite d'usage
            </button>
            <button
              className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
              onClick={onOpenNewPromptModal}
            >
              Nouvelle Prompt
            </button>

            <button
              className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
              onClick={onOpenNewGroupModal}
            >
              Nouvelle Group
            </button>
          </div>
        </header>
        <div className="w-full my-6 border-gray-200 border-2 border-b-0">
          <div className="w-full flex items-center justify-between border-b-2 bg-gray-50 py-3 px-6">
            {promptItems.map((item, index) => (
              <span
                key={item}
                className={`flex-1 text-sm font-medium uppercase text-gray-700 ${
                  item === "Order" ? "text-center" : ""
                } ${index > 0 && "hidden md:block"} `}
              >
                {item}
              </span>
            ))}
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="max-h-[33vh] overflow-auto">
              {prompts
                ?.slice()
                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                ?.map((prompt) => (
                  <PromptItem
                    key={prompt._id}
                    item={prompt}
                    setLoading={setLoading}
                  />
                ))}
            </div>
          )}
        </div>
        <h2 className="font-bold text-xl">Groups</h2>
        <div className="w-full mt-6 border-gray-200 border-2 border-b-0">
          <div className="w-full flex items-center justify-between border-b-2 bg-gray-50 py-3 px-6">
            {groupItems.map((item, index) => (
              <span
                key={item}
                className={`flex-1 text-center text-sm font-medium uppercase text-gray-700 ${index > 0 && "hidden md:block"} `}
              >
                {item}
              </span>
            ))}
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="max-h-[33vh] overflow-auto">
              {groups
                ?.slice()
                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                ?.map((group) => (
                  <div
                    key={group._id}
                    className="w-full flex items-center justify-between border-b-2 bg-gray-50 py-3 px-6"
                  >
                    <div className="flex items-center flex-1">
                      <img
                        className="w-9 h-9 object-cover rounded-full me-2"
                        src={IconGpt}
                        alt="chat gpt icon"
                      />
                      <div>
                        <span className="text-gray-700 block -mb-1 font-semibold text-sm truncate w-24 lg:w-32">
                          {group.name}
                        </span>
                      </div>
                    </div>
                    <div className="flex-1 text-sm text-center lg:text-base text-gray-500">
                      <span>{group.order}</span>
                    </div>
                    <div className="flex-1 text-sm lg:text-base text-gray-500 items-center hidden md:flex justify-center">
                      <i className="me-2">
                        <AiTwotoneCalendar />
                      </i>
                      <span>{new Date(group.updatedAt).toLocaleString()}</span>
                    </div>
                    <div className="flex-1 flex items-center justify-end">
                      <span className=" content-end text-blue-600 underline mr-6 cursor-pointer">
                        <FiEdit
                          color="green"
                          className="cursor-pointer"
                          onClick={() => {
                            setSelectedGroup(group);
                            setGroupName(group.name);
                            setGroupOrder(group.order);
                            onOpenUpdateGroupModal();
                          }}
                        />
                      </span>

                      <div>
                        <FiTrash
                          color="red"
                          className="cursor-pointer"
                          onClick={() => deleteGroup(group._id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {isNewGroupModalOpen && (
        <Modal title="Create New Group" onClose={onCloseNewGroupModal}>
          <form>
            <SimpleInput
              label="Name"
              type="text"
              name="label"
              placeholder="Enter your group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <div className="text-right">
              <button
                type="submit"
                className=" bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                onClick={createNewGroup}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </Modal>
      )}
      {isUpdateGroupModalOpen && (
        <Modal title={`Update Group`} onClose={onCloseUpdateGroupModal}>
          <form>
            <SimpleInput
              label="Name"
              type="text"
              name="label"
              placeholder="Enter your group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <SimpleInput
              type="number"
              name="order"
              value={groupOrder}
              onChange={(e) => setGroupOrder(e.target.value)}
              min={0}
              label="Ordre"
            />
            <div className="text-right">
              <button
                type="submit"
                className=" bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                onClick={(e) => {
                  e.preventDefault();
                  updateGroup(selectedGroup._id);
                }}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </Modal>
      )}
      {isNewPromptModalOpen && (
        <Modal title="Prompt Basics" onClose={onCloseNewPromptModal}>
          <form>
            <SimpleInput
              label="Name"
              type="text"
              name="label"
              placeholder="Enter your prompt label"
              value={newPromptFormData.label}
              onChange={onNewPromptDataChange("label")}
            />
            <div className="text-right">
              <button
                type="submit"
                className=" bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                onClick={onSubmit}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </Modal>
      )}

      {isLimitModalOpen && (
        <Modal
          title="Configuration de la limite d'utilisation quotidienne"
          onClose={onLimitModalClose}
        >
          <form>
            <SimpleInput
              label="Limite :"
              type="number"
              min="0"
              name="limit"
              value={gptRateLimit}
              onChange={(e) => setGptRateLimit(e.target.value)}
            />
            <div className="text-right">
              <button
                type="submit"
                className=" bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                onClick={onSubmitGptRateLimit}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Prompts;
