import { useDispatch, useSelector } from "react-redux";

import EditModel from "../model/EditModel";
import Loading from "../../../../../../Components/base/Loading";
import PromptInputs from "./inputs";
import { SetSelectedPrompt } from "../../../../store/slice/promptsSlice";
import SimpleInput from "../../../../../../Components/base/SimpleInput";
import { useEffect, useState } from "react";
import SelectInput from "../../../../../../Components/base/SelectInput";
import axios from "../../../../utils/axios";

const tabs = [
  {
    id: "model",
    label: "Model",
    element: <EditModel />,
  },
  {
    id: "input",
    label: "Input",
    element: <PromptInputs />,
  },
];

const EditPromptForm = () => {
  // Store
  const dispatch = useDispatch();
  const { selectedPrompt } = useSelector((state) => state.prompts);
  // States
  const [groups, setGroups] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const onTabChange = (tab) => () => {
    setSelectedTab(tab);
  };

  const onChange = (field) => (event) => {
    let group = selectedPrompt.group;
    if (field === "group") {
      group = groups.find((group) => group.name === event.target.value)._id;
    }

    dispatch(
      SetSelectedPrompt({
        ...selectedPrompt,
        [field]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
        group,
      })
    );
  };

  useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await axios.get("api/v1/prompt-group");
        setGroups(res.data);
        if (!selectedPrompt.group && res.data.length > 0) {
          dispatch(
            SetSelectedPrompt({
              ...selectedPrompt,
              group: res.data[0]._id,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    getGroups();
  }, []);

  const getGroupName = (id) => {
    const group = groups.find((group) => group._id === id);
    return group ? group.name : "";
  };

  const modelsValues = `Temp ${selectedPrompt?.model?.temperature}  | stop ${selectedPrompt?.model?.stop}  | PresencePenalty ${selectedPrompt?.model?.presencePenalty}   | frequencyPenalty ${selectedPrompt?.model?.frequencyPenalty}  | MaxLength ${selectedPrompt?.model?.maxLength}   `;
  if (!selectedPrompt) return <Loading />;

  return (
    <div className="px-0 lg:px-2 md:flex md:justify-between md:gap-2 mt-6 md:mt-0">
      <div className="flex-1 md:flex md:flex-col">
        <label className="inline-flex items-center my-2 mx-2 self-end">
          <input
            type="checkbox"
            name="published"
            className="form-checkbox h-5 w-5 cursor-pointer"
            checked={selectedPrompt.published}
            onChange={onChange("published")}
          />
          <span className="ml-2 text-gray-700">Publier</span>
        </label>
        <SimpleInput
          name="prompt"
          label="Nom"
          value={selectedPrompt.label}
          onChange={onChange("label")}
        />
        <SelectInput
          name="group"
          label="Group"
          defaultValue={getGroupName(selectedPrompt?.group)}
          onChange={onChange("group")}
          options={groups.map((group) => group.name)}
        />
        <SimpleInput
          type="number"
          name="order"
          value={selectedPrompt.order}
          onChange={onChange("order")}
          min={0}
          label="Ordre"
        />

        <div>
          <label htmlFor="prompt" className="block text-sm font-semibold mb-2">
            Prompt
          </label>
          <textarea
            name="prompt"
            cols="30"
            rows="10"
            value={selectedPrompt.requiredTemplate}
            onChange={onChange("requiredTemplate")}
            className="resize-none w-full border-2 border-gray-200 p-3"
          ></textarea>
          {selectedPrompt.promptType === "AI" && (
            <span className="text-xs">{modelsValues}</span>
          )}
        </div>
      </div>
      <div className="lg:w-2/5 md:w-2/4 w-full bg-white flex flex-col">
        <div className="flex justify-between items-center shadow-md">
          {tabs.map((tab) => {
            const isActive = tab.id === selectedTab.id;
            const customStyle = {
              border: "2px solid #eee",
              borderBottomColor: isActive ? "rgba(59, 130, 246,.9)" : "#eee",
              color: isActive ? "rgba(59, 130, 246,.9)" : "inherit",
              height: "5vh",
            };
            return (
              <span
                key={tab.id}
                style={customStyle}
                className={`text-md font-medium cursor-pointer text-center flex-1 grid place-items-center`}
                onClick={onTabChange(tab)}
              >
                {tab.label}
              </span>
            );
          })}
        </div>
        <div className="flex-1 flex flex-col" style={{ height: "85vh" }}>
          {selectedTab.element}
        </div>
      </div>
    </div>
  );
};

export default EditPromptForm;
