import { BACKEND_URL, SCRAPPE_API_URL } from "../../variables";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import {
  genericPatchVisibility,
  genericVisibilityChange,
} from "../../utils/generic";
import toast, { Toaster } from "react-hot-toast";

import Button from "./Button";
import { DEBOUNCE_TYPING_DELAY } from "../../utils/contants";
import { DebounceInput } from "react-debounce-input";
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from "./Modal";
import Select from "react-select";
import axios from "axios";

export default function SupplierFactory(props) {
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(true);
  const [scraped, setScraped] = useState({ loading: false, ready: false });
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState({
    ...(["fournisseur", "Concurrent"].includes(props.Name)
      ? { VisibleThisMonth: true, VisibleNextMonth: true, Produit: "" }
      : {}),
  });
  const [url, setUrl] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [selectedItem, setselectedItem] = useState({ Produit: "" });
  const [key, setkey] = useState("test");
  const [search, setSearch] = useState("");
  const [Niche, setNiche] = useState("");
  const [active, setActive] = useState("");

  const token = window.localStorage.getItem("token");

  const [visibilityChanges, setVisibilityChanges] = useState({});

  const visibitilyQuery = {
    VisibleThisMonth: active === "this" ? true : "",
    VisibleNextMonth: active === "next" ? true : "",
    VisibleAllTime: active === "allTime" ? true : "",
  };

  const handleVisibilityChange = (valueToChange, fullValueToChange, i) => {
    genericVisibilityChange(
      "Fournisseur",
      i,
      valueToChange,
      fullValueToChange,
      visibilityChanges,
      setVisibilityChanges,
      Data,
      setData
    );
  };

  const refresh = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: 0,
          Fournisseur: search,
          Niche: Niche,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(0);
        setData(data.data);
        if (props.Name === "Category") {
          window.localStorage.setItem("Category", JSON.stringify(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchData = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: page,
          Fournisseur: search,
          Niche: Niche,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(page + 1);
        if (data.data.length === 0 || data.data.length < (page + 1) * 20) {
          setEnd(false);
        } else if (end == false) {
          setEnd(true);
        }
        setData([...Data, ...data.data]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: 0,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(1);
        setData(data.data);
        data.data.filter((e) => {
          return Object.keys(e).map((k) => e[k]);
        });
        console.log(data.data);
      })
      .catch((err) => console.log(err));
  }, [props]);

  useEffect(() => {
    fetchData();
  }, [Niche, active, search]);

  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-full mx-auto px-4 sm:px-8">
        <div class="py-4">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">{props.Names}</h2>
          </div>
          <div class="my-2 flex sm:flex-row flex-col justify-between">
            <div class="flex">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    value={Niche}
                    onChange={(e) => {
                      setNiche(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout les niches</option>
                    {props.Category.map((cat) => (
                      <option value={cat.name}>{cat.name}</option>
                    ))}{" "}
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="relative">
                  <select
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout</option>
                    <option value="this">Active ce mois</option>
                    <option value="next">Active le mois prochain</option>
                    <option value="allTime">Active tout le temps</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <DebounceInput
                  minLength={2}
                  debounceTimeout={DEBOUNCE_TYPING_DELAY}
                  onChange={(event) => {
                    setPage(0);
                    setData([]);
                    setSearch(event.target.value);
                  }}
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            {Object.keys(visibilityChanges).length !== 0 && (
              <div
                onClick={async () => {
                  setShowSaveModal(true);
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Enregistrer
              </div>
            )}
            {Object.keys(visibilityChanges).length !== 0 && (
              <div
                onClick={async () => {
                  setVisibilityChanges({});
                  setPage(0);
                  setData([]);
                  refresh();
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Rafraichir
              </div>
            )}
            <div
              onClick={() => {
                setShowModal(true);
              }}
              className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
            >
              Créer Un {props.Name}
            </div>
          </div>

          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={end}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {" "}
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {props.columns?.map((i) => (
                        <th class="px-5 py-3 w-96 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          {i.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((i, index) => (
                      <tr
                        onDoubleClick={(e) => {
                          setselectedItem(i);
                          setShowEditModal(!showEditModal);
                        }}
                        className="group"
                      >
                        {props.columns.map((col) => {
                          if (col.type === "text") {
                            return (
                              <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {i[col.db]}
                                </p>
                              </td>
                            );
                          }
                          if (col.type === "checkbox") {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 bg-blue-200 outline-none"
                                  checked={i[col.db]}
                                  disabled={
                                    col.db !== "VisibleAllTime" &&
                                    i["VisibleAllTime"]
                                  }
                                  onClick={() => {
                                    if (col.db === "VisibleAllTime") {
                                      handleVisibilityChange(
                                        "allTime",
                                        "VisibleAllTime",
                                        i
                                      );
                                    } else if (col.db === "VisibleThisMonth") {
                                      handleVisibilityChange(
                                        "thisMonth",
                                        "VisibleThisMonth",
                                        i
                                      );
                                      return;
                                    } else if (col.db === "VisibleNextMonth") {
                                      handleVisibilityChange(
                                        "nextMonth",
                                        "VisibleNextMonth",
                                        i
                                      );
                                    } else {
                                      return;
                                    }
                                  }}
                                />
                              </td>
                            );
                          }
                          if (col.type === "file" && !col.pdf) {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <img
                                  src={`${i[col.db]}`}
                                  height="40"
                                  width="40"
                                />
                              </td>
                            );
                          }
                          if (col.pdf) {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <a href={`${i[col.db]}`}>Lien pdf</a>
                              </td>
                            );
                          }

                          return (
                            <td class="group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <a
                                target={"_blank"}
                                className="underline text-blue-400"
                                href={i[col.db]}
                              >
                                {" "}
                                Link
                              </a>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing 1 to 4 of 50 Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        active={showSaveModal}
        toggler={() => {
          setShowSaveModal(false);
        }}
      >
        <DialogHeader
          toggler={() => {
            setShowSaveModal(false);
          }}
        ></DialogHeader>
        <DialogBody>
          <div class="flex flex-col justify-center items-center">
            <h1 class="font-large text-xl pb-3">
              Veuillez confirmer les changements
            </h1>
            <table class="bg-white text-gray-900 table-auto border-separate w-full shadow-md">
              <thead>
                <tr>
                  <th class="bg-blue-700 text-white p-1 w-auto">
                    Nom Fournisseur
                  </th>
                  <th class="bg-blue-700 text-white p-1">Visible</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(visibilityChanges).map((item, index) => {
                  return (
                    <tr key={index} class="bg-blue-100 text-blue-900">
                      <td class="p-1 flex flex-row justify-center items-center">
                        {visibilityChanges[item].name}
                      </td>
                      <td class="p-1">
                        <div class="flex flex-row justify-center items-center">
                          <input
                            type="checkbox"
                            id="old all time"
                            checked={visibilityChanges[item].old.allTime}
                          />
                          <p>&emsp;&rarr;&emsp;</p>
                          <input
                            type="checkbox"
                            id="new all time"
                            checked={visibilityChanges[item].new.allTime}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setShowSaveModal(false);
            }}
            ripple="dark"
          >
            fermer
          </Button>

          <Button
            color="green"
            onClick={() => {
              genericPatchVisibility(
                "fournisseur",
                BACKEND_URL,
                visibilityChanges,
                token,
                setShowSaveModal,
                setVisibilityChanges,
                refresh
              );
            }}
            ripple="light"
          >
            Confirmer
          </Button>
        </DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showModal}
        toggler={() => {
          setScraped({ loading: false, ready: false });
          setShowModal(false);
        }}
      >
        <DialogHeader
          toggler={() => {
            setScraped({ loading: false, ready: false });
            setShowModal(false);
          }}
        ></DialogHeader>
        <DialogBody>
          {/* <div className="flex flex-row ">
            <div className="flex flex-row ">
              <div className="my-auto mr-7 font-medium">
                Entrer le lien du produit
              </div>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Lien"
                className="border-gray-100 border rounded-lg outline-none px-1"
              />
            </div>
            <div
              onClick={async () => {
                setScraped({ ...scraped, loading: true });
                axios
                  .post(
                    `${SCRAPPE_API_URL}/api/v1/scrapeproduct`,
                    { url: url },
                    {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    setScraped({
                      loading: false,
                      ready: true,
                      ...response.data.storeInfo,
                      url: url,
                    });
                    setNewItem({
                      ...newItem,
                      Fournisseur: response.data.storeInfo.name,
                      Lien: `https://fr.aliexpress.com/store/${response.data.storeInfo.storeNumber}`,
                      Rating: response.data.storeInfo.rating,
                      Followers: response.data.storeInfo.followers,
                    });
                  })
                  .catch((err) => console.log("oopsie"));
              }}
              className="ml-6 border rounded-2xl hover:bg-purple-200 py-2 px-6  font-semibold border-gray-200 cursor-pointer"
            >
              Charger
            </div>
          </div> */}

          <div className="flex flex-row w-full flex-wrap ">
            {!["user", "Video", "Calendar", "adspy", "Category"].includes(
              props.Name
            ) && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  choisir une niche
                </div>
                {props.Name !== "user" && (
                  <Select
                    className={` basic-single w-full ${
                      newItem.Niche
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    }`}
                    classNamePrefix="select"
                    value={{
                      label: newItem.Niche || "",
                      value: newItem.Niche || "",
                    }}
                    onChange={(e) => setNewItem({ ...newItem, Niche: e.value })}
                    isClearable={false}
                    isSearchable={false}
                    name="color"
                    options={props.Category.map((i) => {
                      return { label: i.name, value: i.name };
                    })}
                  />
                )}
              </div>
            )}
            {props.columns
              .filter((i) => i.text !== "Niche")
              .map((i) => (
                <div className="flex flex-row w-1/2 mt-5">
                  <div
                    className={`ml-6 ${i.db === "VisibleAllTime" ? "font-bold" : "font-medium"} mr-3 my-auto w-80`}
                  >
                    {i.text}:
                  </div>
                  {i.type === "text" && i.db !== "Validity" && (
                    <input
                      placeholder={i.text}
                      value={newItem[i.db] || ""}
                      onChange={(e) =>
                        setNewItem({ ...newItem, [i.db]: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        newItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type === "textOptions" && i.db !== "Validity" && (
                    <Select
                      placeholder={i.text}
                      value={
                        newItem[i.db]
                          ? { label: newItem[i.db], value: newItem[i.db] }
                          : { label: "", value: "" }
                      }
                      onChange={(e) =>
                        setNewItem({ ...newItem, [i.db]: e.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        newItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      options={i.options.map((op) =>
                        Object.create({ label: op, value: op })
                      )}
                    />
                  )}
                  {i.db === "Validity" && (
                    <input
                      type="date"
                      placeholder={i.text}
                      value={newItem[i.db] || ""}
                      onChange={(e) =>
                        setNewItem({ ...newItem, [i.db]: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        newItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type === "link" && (
                    <input
                      placeholder={i.text}
                      value={newItem[i.db] || ""}
                      onChange={(e) =>
                        setNewItem({ ...newItem, [i.db]: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        newItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type === "checkbox" && (
                    <div className="flex flex-row w-full">
                      <input
                        type="checkbox"
                        checked={newItem[i.db]}
                        disabled={
                          i.db !== "VisibleAllTime" && newItem["VisibleAllTime"]
                        }
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.target.checked })
                        }
                        className={`p-1 w-4 h-4 outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg my-auto`}
                      />
                    </div>
                  )}
                  {i.type == "file" && (
                    <input
                      name="file"
                      className={`w-full ${
                        newItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg p-1`}
                      type="file"
                      key={key}
                      onChange={(e) => {
                        e.preventDefault();
                        setNewItem({ ...newItem, [i.db]: e.target.files[0] });
                      }}
                    />
                  )}
                </div>
              ))}
            {props.Name == "user" && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Mot de passe
                </div>
                <input
                  placeholder={"Mot de passe "}
                  value={newItem?.password || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, password: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    newItem["password"]
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                  rows="5"
                />
              </div>
            )}

            <div className="w-full flex flex-row justify-end">
              <Button
                onClick={(e) => setShowModal(false)}
                className="rounded-lg px-4 py-2 text-red-500 hover:bg-red-100 mr-3 font-semibold"
              >
                Annuler
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const formData = new FormData();
                  props.columns.map((i) => {
                    if (i.type === "file") {
                      formData.append(i.db, newItem[i.db], newItem[i.db].name);
                    } else if (i.db === "Validity") {
                      formData.append(i.db, new Date(newItem[i.db]));
                    } else {
                      formData.append(i.db, newItem[i.db]);
                    }
                  });
                  if (props.Name === "user") {
                    formData.append("password", newItem.password);
                  }
                  axios
                    .post(`${BACKEND_URL}/api/v1/${props.Name}`, formData, {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    })
                    .then((Response) => {
                      refresh();
                      setNewItem({
                        Image: "",
                        ...(["fournisseur", "Concurrent"].includes(props.Name)
                          ? {}
                          : {}),
                      });
                      toast.success(`🥳 un ${props.Name} a été crée`);
                      setkey(null);
                      setShowModal(false);
                    })
                    .catch((err) => {
                      setNewItem({});
                      toast.error(`😥 une erreur est survenue`);
                      setShowModal(false);
                    });
                }}
                className="rounded-lg px-4 py-2 bg-green-500 text-white font-semibold hover:text-gray-700 hover:bg-green-100 ml-6"
              >
                Créer
              </Button>
            </div>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showEditModal}
        toggler={() => {
          setShowEditModal(false);
          setTimeout(() => {
            setselectedItem({ Produit: "", Rating: "", Followers: "" });
          }, 150);
        }}
      >
        <DialogHeader
          toggler={() => {
            setShowEditModal(false);
            setTimeout(() => {
              setselectedItem({ Produit: "", Rating: "", Followers: "" });
            }, 150);
          }}
        ></DialogHeader>
        <DialogBody>
          <div className="flex flex-row w-full flex-wrap ">
            {!["user", "Video", "Calendar", "adspy", "Category"].includes(
              props.Name
            ) && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">Niche</div>
                <Select
                  className={` basic-single w-full ${
                    selectedItem.Niche
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  }`}
                  value={{
                    label: selectedItem.Niche,
                    value: selectedItem.Niche,
                  }}
                  classNamePrefix="select"
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Niche: e.value })
                  }
                  isClearable={false}
                  isSearchable={false}
                  name="color"
                  options={props.Category.map((i) => {
                    return { label: i.name, value: i.name };
                  })}
                />
              </div>
            )}
            {props.columns
              .filter((i) => i.text !== "Niche")
              .map((i) => (
                <div className="flex flex-row w-1/2 mt-5">
                  <div
                    className={`ml-6 ${i.db === "VisibleAllTime" ? "font-bold" : "font-medium"} mr-3 my-auto w-80`}
                  >
                    {i.text}:
                  </div>
                  {i.type == "text" && i.db !== "Validity" && (
                    <input
                      placeholder={i.text}
                      value={selectedItem[i.db]}
                      onChange={(e) =>
                        setselectedItem({
                          ...selectedItem,
                          [i.db]: e.target.value,
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        selectedItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type == "textOptions" && i.db !== "Validity" && (
                    <Select
                      placeholder={i.text}
                      value={
                        selectedItem[i.db]
                          ? {
                              label: selectedItem[i.db],
                              value: selectedItem[i.db],
                            }
                          : { label: "", value: "" }
                      }
                      onChange={(e) =>
                        setselectedItem({
                          ...selectedItem,
                          [i.db]: e.value,
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        selectedItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      options={i.options.map((op) =>
                        Object.create({ label: op, value: op })
                      )}
                    />
                  )}
                  {i.db == "Validity" && (
                    <input
                      type="date"
                      value={
                        selectedItem[i.db]?.includes("T")
                          ? selectedItem[i.db].split("T")[0]
                          : selectedItem[i.db]
                      }
                      onChange={(e) => {
                        /* console.log(
                          typeof e.target.valueAsDate.toLocaleDateString()
                        );
                        console.log(new Date(e.target.value).toJSON()); */
                        setselectedItem({
                          ...selectedItem,
                          [i.db]: new Date(e.target.value).toJSON(),
                        });
                      }}
                      className={`p-1 w-full outline-none  ${
                        selectedItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type == "link" && (
                    <input
                      placeholder={i.text}
                      value={selectedItem[i.db]}
                      onChange={(e) =>
                        setselectedItem({
                          ...selectedItem,
                          [i.db]: e.target.value,
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        selectedItem[i.db]
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  )}
                  {i.type == "checkbox" && (
                    <div className="flex flex-row w-full ">
                      <input
                        type="checkbox"
                        checked={selectedItem[i.db]}
                        onChange={(e) =>
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: e.target.checked,
                          })
                        }
                        className={`p-1 w-4 h-4 outline-none  ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg my-auto`}
                        rows="5"
                      />
                    </div>
                  )}
                  {i.type == "file" && !i.pdf && (
                    <div className="flex flex-col w-full justify-center flex-wrap">
                      {typeof selectedItem.Image == "string" && (
                        <img
                          height="120"
                          className="mx-auto"
                          width="120"
                          src={`${selectedItem.Image}`}
                        />
                      )}
                      {typeof selectedItem.Image == "string" && (
                        <div className="mx-auto">ou</div>
                      )}
                      <input
                        name="file"
                        className={` w-2/3 mx-auto ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg p-1`}
                        type="file"
                        onChange={(e) => {
                          e.preventDefault();
                          setselectedItem({
                            ...selectedItem,
                            Image: e.target.files[0],
                          });
                        }}
                      />
                    </div>
                  )}
                  {i.type == "file" && i.pdf && (
                    <div className="flex flex-col w-full justify-center flex-wrap">
                      {typeof selectedItem.Image == "string" && (
                        <a
                          className="text-blue-400"
                          href={`${selectedItem.Image}`}
                        >
                          Lien pdf
                        </a>
                      )}
                      {typeof selectedItem.Image == "string" && (
                        <div className="mx-auto">ou</div>
                      )}
                      <input
                        name="file"
                        className={` w-2/3 mx-auto ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg p-1`}
                        type="file"
                        onChange={(e) => {
                          e.preventDefault();
                          setselectedItem({
                            ...selectedItem,
                            Image: e.target.files[0],
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </DialogBody>
        <DialogFooter>
          {/* <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => {
                            setShowEditModal(false);
                                    setselectedItem({})
                        }}
                        ripple="dark"
                    >
                        Annuler
                    </Button> */}
          <Button
            color="red"
            buttonType="link"
            onClick={(e) =>
              axios
                .post(
                  `${BACKEND_URL}/api/v1/${props.Name}delete`,
                  {
                    ...selectedItem,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  toast.success(`🥳 un ${props.Name} a été supprimé`);
                  setShowEditModal(false);
                  setselectedItem({
                    ...(["fournisseur", "Concurrent"].includes(props.Name)
                      ? {}
                      : {}),
                  });
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                })
            }
            ripple="dark"
          >
            Supprimer
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              e.preventDefault();
              if (selectedItem?.Validity) {
                if (typeof selectedItem?.Validity == "string") {
                  //console.log(new Date(selectedItem?.Validity).toDateString());
                  /* setselectedItem({
                    ...selectedItem,
                    Validity: new Date(selectedItem?.Validity),
                  }); */
                }
              }
              const formData = new FormData();
              Object.keys(selectedItem).map((i) => {
                //  console.log(typeof selectedItem.Image);

                if (i === "Image") {
                  if (typeof selectedItem.Image == "object") {
                    formData.append(
                      "Image",
                      selectedItem.Image,
                      selectedItem.Image.name
                    );
                  }
                } else if (i.db === "Validity") {
                  formData.append(i.db, selectedItem[i.db]);
                } else if (i.db === "LastSeen") {
                  formData.append(i.db, new Date());
                } else {
                  formData.append(i, selectedItem[i]);
                }
              });
              axios
                .put(`${BACKEND_URL}/api/v1/${props.Name}`, formData, {
                  headers: {
                    authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  console.log("done");
                  refresh();
                  toast.success(`🥳 un ${props.Name} a été mis à jour`);
                  setShowEditModal(false);
                  setselectedItem({
                    ...(["fournisseur", "Concurrent"].includes(props.Name)
                      ? {}
                      : {}),
                  });
                  /* setScraped({ loading: false, ready: false })
                                setselectedImages([])
                                setKeywords([])
                                setUrl('') */
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                });
            }}
            ripple="light"
          >
            Sauvegarder
          </Button>
        </DialogFooter>
      </Modal>
    </div>
  );
}
